import { useEffect, useState } from "react";
import { Dropdown, MultiSelect } from 'primereact';
import personIcon from '../../assets/images/personIcon.svg';
import revenueIcon from '../../assets/images/revenueIcon.svg';
import listIcon from '../../assets/images/listIcon.svg';
import campaignIcon from '../../assets/images/campaignIcon.svg';
import { Row, Col } from "react-bootstrap";
import Button from "../General/Button";
import ModalFiltersReports from "../Reports/modalFiltersReports";
import ModalCustomDate from "../Reports/constants/modalCustomDate";
import { getDashboardNewHome } from '../../services/newHomeService';
import { dates } from "../Reports/constants/dates";
import i18n from "../../i18n";
import NewHomeBarChart from "../Charts/NewHomeBarChart";
import NewHomePieChart from "../Charts/NewHomePieChart";
import Spinner from 'react-bootstrap/Spinner';
import "./newHome.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { transactionModelTypes } from "../Reports/constants/transactionModelTypes";
import { markets } from "../Reports/constants/markets";
import { inventoryTypes } from "../Reports/constants/inventoryTypes";

const NewHome = () => {
  const [selectedTransactionModel, setSelectedTransactionModel] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState([]);
  const [selectedInventoryType, setSelectedInventoryType] = useState([]);
  const [showModalFiltersReports, setShowModalFiltersReports] = useState(false);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [showModalCustomDate, setShowModalCustomDate] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({ name: 'Last seven days', code: 'seven_days' },);
  const [selectedStartEndDate, setSelectedStartEndDate] = useState(null);
  const [cardsData, setCardsData] = useState(null);
  const [showSpinner, setShowSpinner] = useState(true);
  const [number, setNumber] = useState(0);
  const [selectedDays, setSelectedDays] = useState([]);
  const [impressionsChart, setImpressionsChart] = useState([]);
  const [viewsChart, setViewsChart] = useState([]);
  const [revenueByInventory, setRevenueByInventory] = useState([]);
  const [enableButtonSearch, setEnableButtonSearch] = useState(true);

  
  const getCardsData = (period, startDate, endDate, market, inventoryType, advertisers, clients, model) => {
    setShowSpinner(true);
    setEnableButtonSearch(false);
    getDashboardNewHome(period, startDate, endDate, market, inventoryType, advertisers, clients, model)
    .then((res) => {
      setSelectedDays(res.impressionsViews.map(item => item.selectedDay));
      setImpressionsChart(res.impressionsViews.map(item => item.impressions));
      setViewsChart(res.impressionsViews.map(item => item.completeViewsVideo));
      setRevenueByInventory(res.inventoryRevenue);
      const totalSpent = res.topClients.reduce((sum, client) => sum + client.spentUsd, 0);
      res.topClients.forEach(client => {
        client.percentage = ((client.spentUsd / totalSpent) * 100).toFixed(2);
      });
      setCardsData(res);
      setShowSpinner(false);
      setEnableButtonSearch(true);
    });
  }

  const handleGetCardsData = () => {
    const period = (selectedDateRange && selectedDateRange.code !== "customDate") ? selectedDateRange.code : null;
    const startDate = selectedDateRange.code === "customDate" ? selectedStartEndDate.startDate : null;
    const endDate = selectedDateRange.code === "customDate" ? selectedStartEndDate.endDate : null;
    const market = selectedMarket.map(market => market.name);
    const inventoryType = selectedInventoryType.map(inventory => inventory.id);
    const advertisers = selectedAdvertisers.map(advertiser => advertiser.id);
    const clients = selectedClients.map(client => client.id);
    const model = selectedTransactionModel.map(model => model.id);
    getCardsData(period, startDate, endDate, market, inventoryType, advertisers, clients, model);
  }

  const handleReset = () => {
    setSelectedTransactionModel([]);
    setSelectedMarket([]);
    setSelectedInventoryType([]);
    setSelectedAdvertisers([]);
    setSelectedClients([]);
    setSelectedDateRange({ name: 'Last seven days', code: 'seven_days' });
    setSelectedStartEndDate(null);
    setCardsData(null);
    setNumber(0);
    getCardsData('seven_days', null, null, [], [], [], [], []);
  }

  useEffect(() => {
    handleGetCardsData();
    const interval = setInterval(() => {
      setNumber((prevNumber) => prevNumber + 1);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (cardsData) {
      clearInterval();
    }
  }, [cardsData]);

  const handleCloseCustomDateModal = () => {
    setShowModalCustomDate(false);
  }

  const handleCloseFiltersReportsModal = () => {
    setShowModalFiltersReports(false);
  }

  const handlePerformanceReport = () => {
    console.log('test');
  };

  const getSelectedItems = (filter) => {
    switch (filter) {
      case i18n.advanceReportsAdvertiser:
        return selectedAdvertisers;
      case i18n.advanceReportsClient:
        return selectedClients;
      default:
        return [];
    }
  };

  const getSetSelectedItems = (filter) => {
    switch (filter) {
      case i18n.advanceReportsAdvertiser:
        return setSelectedAdvertisers;
      case i18n.advanceReportsClient:
        return setSelectedClients;
      default:
        return () => {};
    }
  };

  useEffect(() => {
    if(selectedDateRange === 'customDate') {
      setSelectedDateRange({ name: 'Custom date', code: 'customDate' });
    }      
  }, [selectedDateRange]);

  return (
    <div className="content_outer">
      <div className="content pb-0">
        <div className="newHomePage">
          <div className="newHomeContent">
            <section className="newHomeTitle">
              <p>Welcome back {`${JSON.parse(localStorage.getItem("userdata")).first_name}`}</p>
            </section>
            <section className="newHomeSelectors">
              <Row>
                <Col xs={10} className="newHomeSelectorsCol">
                  <div className="newHomeMultiselectDiv">
                    <Dropdown
                      value={selectedDateRange}
                      onChange={(e) => {
                        e.target.value.code === "customDate"
                          ? setShowModalCustomDate(true)
                          : setSelectedDateRange(e.target.value);
                      }}
                      options={dates}
                      optionLabel="name" 
                      placeholder="Select a date"
                      className="newHomeMultiselect"
                    />
                  </div>
                  <div className="newHomeMultiselectDiv">
                    <MultiSelect value={selectedTransactionModel} onChange={(e) => setSelectedTransactionModel(e.value)} options={transactionModelTypes} optionLabel="name" 
                      placeholder="Model" maxSelectedLabels={2} className="newHomeMultiselect" />
                  </div>
                  <div className="newHomeMultiselectDiv">
                    <div
                      onClick={() => {
                        setShowModalFiltersReports(true)
                        setSelectedFilter(i18n.advanceReportsAdvertiser)                        
                      }}
                      className="newHomeFormatsContainer newHomeMultiselect p-multiselect-label"
                    >
                        {selectedAdvertisers.length > 0 ? selectedAdvertisers.length + (selectedAdvertisers.length > 1 ? ' items selected' : ' item selected') : 'Advertiser'}
                    </div>
                  </div>
                  <div className="newHomeMultiselectDiv">
                    <div
                      onClick={() => {
                        setShowModalFiltersReports(true)
                        setSelectedFilter(i18n.advanceReportsClient)                        
                      }}
                      className="newHomeFormatsContainer newHomeMultiselect p-multiselect-label"
                    >
                        {selectedClients.length > 0 ? selectedClients.length + (selectedClients.length > 1 ? ' items selected' : ' item selected') : 'Client SAAS'}
                    </div>
                  </div>
                  <div className="newHomeMultiselectDiv">
                    <MultiSelect value={selectedMarket} onChange={(e) => setSelectedMarket(e.value)} options={markets} optionLabel="name"
                      placeholder="Market" maxSelectedLabels={3} className="newHomeMultiselect" />
                  </div>
                  <div className="newHomeMultiselectDiv">
                    <MultiSelect value={selectedInventoryType} onChange={(e) => setSelectedInventoryType(e.target.value) } options={inventoryTypes} optionLabel="name"
                      placeholder="Inventory" maxSelectedLabels={3} className="newHomeMultiselect" />
                  </div>
                </Col>
                <Col xs={2} className="newHomeSelectorsColButtons">
                  <h3
                    className={`newHomeSelectorsColButtonsReset ${!enableButtonSearch ? 'disableNewHomeSelectorsColButtonsReset' : ''}`}
                    onClick={handleReset}>
                      Reset
                  </h3>
                  <Button
                    text="Search"
                    className={`standardSizeButton ${!enableButtonSearch ? 'disabledButton' : ''}`}
                    onClick={handleGetCardsData}/>
                </Col>
              </Row>
            </section>
            
            <section className="newHomeQuadrants">
              <div className="newHomeQuadrant">
                <img src={personIcon} className="reportIcons cursorAuto" />
                <h3 className="newHomeQuadrantTitle">{i18n.activeSeatsDynamic}</h3>
                <h4 className="newHomeQuadrantValue">                  
                  {cardsData ? cardsData.activeTotalCards.totalMediaPlanDynamic : number}
                </h4>  
              </div>
              <div className="newHomeQuadrant">
                <img src={campaignIcon} className="reportIcons cursorAuto" />
                <h3 className="newHomeQuadrantTitle">{i18n.activeCampaignsDynamic}</h3>
                <h4 className="newHomeQuadrantValue">
                  {cardsData ? cardsData.activeTotalCards.totalCampaignDynamic : number}
                </h4>
              </div>
              <div className="newHomeQuadrant">
                <img src={listIcon} className="reportIcons cursorAuto" />
                <h3 className="newHomeQuadrantTitle">{i18n.activeLineitemsDynamic}</h3>
                <h4 className="newHomeQuadrantValue">
                  {cardsData ? cardsData.activeTotalCards.totalLineitemDynamic : number}
                </h4>
              </div>
              <div className="newHomeQuadrant">
                <img src={revenueIcon} className="reportIcons cursorAuto" />
                <h3 className="newHomeQuadrantTitle">{i18n.revenueDynamic}</h3>
                <h4 className="newHomeQuadrantValue">
                  {cardsData ? Math.round(cardsData.revenueDynamic).toLocaleString('en-US') : number}
                  </h4>
              </div>
              <div className="newHomeQuadrant">
                <img src={personIcon} className="reportIcons cursorAuto" />
                <h3 className="newHomeQuadrantTitle">{i18n.activeMediaPlanFp}</h3>
                <h4 className="newHomeQuadrantValue">
                  {cardsData ? cardsData.activeTotalCards.totalMediaPlanFp : number}
                </h4>
              </div>
              <div className="newHomeQuadrant">
                <img src={campaignIcon} className="reportIcons cursorAuto" />
                <h3 className="newHomeQuadrantTitle">{i18n.activeCampaigns}</h3>
                <h4 className="newHomeQuadrantValue">
                  {cardsData ? cardsData.activeTotalCards.totalCampaignFp : number}
                </h4>
              </div>
              <div className="newHomeQuadrant">
                <img src={listIcon} className="reportIcons cursorAuto" />
                <h3 className="newHomeQuadrantTitle">{i18n.activeLineitems}</h3>
                <h4 className="newHomeQuadrantValue">
                  {cardsData ? cardsData.activeTotalCards.totalLineitemFp : number}
                </h4>
              </div>
              <div className="newHomeQuadrant">
                <img src={revenueIcon} className="reportIcons cursorAuto" />
                <h3 className="newHomeQuadrantTitle">{i18n.revenueFp}</h3>
                <h4 className="newHomeQuadrantValue">
                  {cardsData ? Math.round(cardsData.revenueFp).toLocaleString('en-US') : number}
                </h4>
              </div>
            </section>

            {showSpinner ? (
              <div className="divSpinnerFlex">
                <Spinner className="loaderSpinner" animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
            <>
            <section className="newHomeRevenue">
              <Row>
                <Col xs={12} sm={6}>
                  <div className="newHomeRevenueChart">
                    <div className="newHomeRevenueChartHeader">
                    <h3 className="newHomeRevenueChartTitle">Top 5 clients by revenue</h3>
                    </div>
                    {cardsData.topClients.length > 0 ? (
                      <>
                        <div className="newHomeRevenueBarChartColumnHeaders">
                          <span className="newHomeRevenueBarChartHeaderName">Client name</span>
                          <span className="newHomeRevenueBarChartHeaderRevenue">Revenue</span>
                        </div>
                        <div className="newHomeRevenueBarChart">
                          {cardsData.topClients.map((client) => (
                            <div key={client.advertiser} className="newHomeRevenueBarChartRow">
                              <span className="newHomeRevenueBarChartClientName">{client.advertiser}</span>
                              <div className="newHomeRevenueBarChartContainer">
                                <div 
                                  className="newHomeRevenueBarChartPercentage"
                                  style={{ width: `${client.percentage}%` }}
                                />
                              </div>
                              <span className="newHomeRevenueBarChartResult">{client.spentUsd.toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                  })}
                              </span>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <div className="noDataFound newHomePieChart">
                        <p className="newHomeRevenueChartText">No data available</p>
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="newHomeRevenueChart newHomePieChart">
                    <h3 className="newHomeRevenueChartTitle">Split revenue by inventory</h3> 
                    <NewHomePieChart
                      chartData={revenueByInventory}
                    />
                  </div>
                </Col>
              </Row>
            </section>

            <section className="newHomeStatistics">
              <div className="newHomeStatisticsSingle">
                <h3 className="newHomeStatisticsValue">
                {cardsData.totalImpressions.toLocaleString("en-US")}
                </h3>
                Impressions
              </div>

              <Row className="newHomeStatisticsDouble">
                <Col xs={7} className="newHomeStatisticsDoubleFlex newHomeStatisticsDoubleViews">
                  <h3 className="newHomeStatisticsValue">
                    {(cardsData.totalViews.toLocaleString("en-US"))}
                  </h3>
                  CompletedViews
                </Col>
                <Col xs={5} className="newHomeStatisticsDoubleStats newHomeStatisticsDoubleVtr">
                  <h3 className="newHomeStatisticsValue">
                    {(cardsData.totalVtr.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }))}%</h3>
                  VTR
                </Col>
              </Row>

              <Row className="newHomeStatisticsDouble">
                <Col xs={7} className="newHomeStatisticsDoubleFlex newHomeStatisticsDoubleClicks">
                  <h3 className="newHomeStatisticsValue">
                    {(cardsData.totalClicks.toLocaleString("en-US"))}
                  </h3>
                  Clicks
                </Col>
                <Col xs={5} className="newHomeStatisticsDoubleStats newHomeStatisticsDoubleCtr">
                  <h3 className="newHomeStatisticsValue">
                    {(cardsData.totalCtr.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }))}%</h3>
                  CTR
                </Col>
              </Row>

              <Row className="newHomeStatisticsDouble">
                <Col xs={7} className="newHomeStatisticsDoubleFlex newHomeStatisticsDoubleEng">
                  <h3 className="newHomeStatisticsValue">
                  {(cardsData.totalEngagements.toLocaleString("en-US"))}
                  </h3>
                  Engagements
                </Col>
                <Col xs={5} className="newHomeStatisticsDoubleStats newHomeStatisticsDoubleEr">
                  <h3 className="newHomeStatisticsValue">
                    {(cardsData.totalEr.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }))}%
                  </h3>
                    ER
                </Col>
              </Row>
            </section>           

            <section className="newHomeColumnChart">
              <NewHomeBarChart
                selectedDays={selectedDays}
                impressionsChart={impressionsChart}
                viewsChart={viewsChart}
              />
            </section>            

            <section className="newHomeFormatsSection">
              <div className="newHomeFormatsContainer">
                <h3 className="newHomeFormatsSectionTitle">Split by format</h3>
                <table className="newHomeFormatsTable">
                  <thead>
                    <tr className="newHomeFormatsTableHeader">
                      <th>Format</th>
                      <th>Inventory Type</th>
                      <th>Impressions</th>
                      <th>Views</th>
                      <th>Clicks</th>
                      <th>Engagements</th>
                      <th>Spent usd</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cardsData.splitByFormat.map((item, index) => (
                      <tr key={index} className="newHometrFormatTable">
                        <td>{item.format}</td>
                        <td>{item.inventoryType}</td>
                        <td>{item.impressions.toLocaleString()}</td>
                        <td>{item.completeViewsVideo.toLocaleString()}</td>
                        <td>{item.clicks.toLocaleString()}</td>
                        <td>{item.engagements.toLocaleString()}</td>
                        <td>{item.spentUsd || item.spentUsdSaas ? (item.spentUsd + item.spentUsdSaas).toLocaleString("en-US", { maximumFractionDigits: 2 })  : '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
            </>
            )}

            <ModalFiltersReports
              show={showModalFiltersReports}
              handleClose={handleCloseFiltersReportsModal}
              title={selectedFilter}
              selectedItems={getSelectedItems(selectedFilter)}
              setSelectedItems={getSetSelectedItems(selectedFilter)}
              handlePerformanceReport={handlePerformanceReport}
              selectedAdvertisers={selectedAdvertisers}
              selectedAdvertiserSaas={[]}
              selectedClients={selectedClients}
            />

            <ModalCustomDate
              show={showModalCustomDate}
              handleClose={handleCloseCustomDateModal}
              setSelectedStartEndDate={setSelectedStartEndDate}
              setSelectedDateRange={setSelectedDateRange}
              setShowModalCustomDate={setShowModalCustomDate}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewHome;